<template>
  <sign-page
    title-text="办公会议题征集"
    :request="request"
    table-size="large"
    :title-menus="[{key: 'new', label: '新增'}]"
    :tableActions="tableActions"
    @clickTitleMenu="chooseData=null;modal = true"
    @tableAction="tableAction"
    :column-list="columnList"
    ref="docPage"
    :table-actions-fixed="true"
    :tableActionsWidth="200"
    :need-data-file="true">
    <fm-modal v-model="modal" v-if="modal" width="1100px">
      <doc-form :id="chooseData ? chooseData.id : null" @dataUpdate="$refs.docPage.loadData()" @close="modal = false" :workerUserList="$store.getters.workerUserList" v-if="modal"></doc-form>
    </fm-modal>
  </sign-page>
</template>

<script>
import DocForm from './cmp/form'
import DocTitle from './cmp/docTitle'

import {
  officialDocumentRequest as request
} from '../../api'

export default {
  components: {
    DocForm
  },
  created () {
    this.$store.dispatch('loadWorkerUserList')
  },
  methods: {
    async loadData (parm) {
      return await request.get(Object.assign({}, parm, {
        type: 'issues'
      }))
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'edit_all') {
        this.modal = true
      } else if (action === 'over') {
        this.chooseData.status = 'over'
        await request.update(this.chooseData.id, this.chooseData)
        this.$notice.success({
          title: '系统提示',
          desc: '办公会议题征集已结束。'
        })
        this.$refs.docPage.loadData()
      } else if (action === 'detail') {
        this.$router.push({
          name: 'hrms.issues.detail',
          query: {
            id: this.chooseData.id
          }
        })
      } else if (action === 'back') {
        await request.back(this.chooseData.id)
        this.$notice.success({
          title: '系统提示',
          desc: '办公会议题征集可继续处理。'
        })
        this.$refs.docPage.loadData()
      }
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'edit_all',
        label: '编辑',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'detail',
        label: '查看'
      },
      {
        key: 'back',
        label: '继续处理',
        show (data) {
          return data.status === 'over'
        }
      },
      {
        key: 'over',
        label: '结束',
        show (data) {
          return data.status === 'doing'
        }
      },
      {
        key: 'del',
        label: '删除',
        show (data) {
          return data.status === 'doing'
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '议题编号',
          sort: true,
          field: 'sendCode'
        },
        {
          title: '议题题目',
          sort: true,
          field: 'title',
          render: (h, row) => {
            return h(DocTitle, {
              props: {
                title: row.title
              }
            })
          }
        },
        {
          title: '提交部门',
          sort: true,
          field: 'sendOrgName'
        },
        {
          title: '提交时间',
          sort: true,
          field: 'sendTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.sendTime ? rowData.sendTime.slice(0, 10) : '-')
          }
        },
        {
          title: '科室负责人',
          sort: true,
          field: 'sendLevel'
        },
        {
          title: '状态',
          sort: true,
          field: 'status',
          render: (h, rowData) => {
            return h('div', rowData && rowData.status === 'doing' ? '未结束' : '已结束')
          }
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      chooseData: null,
      modal: false
    }
  }
}
</script>
